<div class="fs-16px messages-filter-menu__container border" *transloco="let t">
  <div class="mr-15px ml-15px pt-15px pb-15px font-weight-bold d-flex align-items-center justify-content-between">
    <span>{{ t("notifications.filter.notification_settings") }}</span>
    <i-feather name="x" class="fs-0px feather-medium cursor-pointer" (click)="closeFilter.emit()"></i-feather>
  </div>
  
  <div class="w-100 border-color-grey border-bottom"></div>
  <div class="p-15px">
    <span class="messages-filter-menu__title" style="margin-left: 0px">{{ t("notifications.filter.account_filter") }}</span>
    <div class="mt-10px d-flex flex-row align-items-center">
      <button
        class="checkbox-circle mr-10px"
        [ngClass]="{ checked: accountTypeViewNoBots }"
        (click)="updateAccountTypeView()"
      >
        <i-feather name="check"></i-feather>
      </button>
      <i-feather name="user-x" class="feather-medium fs-0px mr-5px"></i-feather>
      <span>{{ t("notifications.filter.bots") }}</span>
    </div>
    <div class="mt-10px d-flex flex-row align-items-center">
      <button
        class="checkbox-circle mr-10px"
        [ngClass]="{ checked: accountTypeViewNoMassTags }"
        (click)="updateAccountTypeViewMassTag()"
      >
        <i-feather name="check"></i-feather>
      </button>
      <i-feather name="tag" class="feather-medium fs-0px mr-5px"></i-feather>
      <span>{{ t("notifications.filter.ghosts") }}</span>
    </div>
    <!-- Toggle for "Allow from Followed Creators" -->
    <div class="d-flex flex-row align-items-center ml-4 small-check">
      <button
        class="checkbox-circle mr-2"
        [ngClass]="{ checked: allowFromFollowedCreators }"
        (click)="updateAllowFromFollowedCreators()"
      >
        <i-feather name="check"></i-feather>
      </button>
      <span>{{ t("notifications.filter.allow_from_followed_creators") }}</span>
    </div>
  </div>


  <div class="w-100 border-color-grey border-bottom"></div>

  <div class="p-15px">
    <span class="messages-filter-menu__title" style="margin-left: 0px">
      {{ t("notifications.filter.only_show") }}
      <a (click)="selectAllOrNone()" class="ml-5px fs-12px">
        ({{ allSelected() ? t("notifications.filter.select_none") : t("notifications.filter.select_all") }})
      </a>
    </span>
    <div class="mt-10px d-flex flex-row align-items-center">
      <button
        class="checkbox-circle mr-10px"
        [ngClass]="{ checked: !filteredOutSet.hasOwnProperty('like') }"
        (click)="updateFilters('like')"
      >
        <i-feather name="check"></i-feather>
      </button>
      <i-feather name="heart" class="feather-medium fs-0px mr-5px"></i-feather>
      <span>{{ t("notifications.filter.likes") }}</span>
    </div>

    <div class="mt-5px d-flex flex-row align-items-center">
      <button
        class="checkbox-circle mr-10px"
        [ngClass]="{ checked: !filteredOutSet.hasOwnProperty('diamond') }"
        (click)="updateFilters('diamond')"
      >
        <i-feather name="check"></i-feather>
      </button>
      <i-feather name="diamond" class="feather-medium fs-0px mr-5px"></i-feather>
      <span>{{ t("notifications.filter.diamonds") }}</span>
    </div>

    <div class="mt-5px d-flex flex-row align-items-center">
      <button
        class="checkbox-circle mr-10px"
        [ngClass]="{ checked: !filteredOutSet.hasOwnProperty('transfer') }"
        (click)="updateFilters('transfer')"
      >
        <i-feather name="check"></i-feather>
      </button>
      <i-feather name="coin" class="feather-medium fs-0px mr-5px"></i-feather>
      <span>{{ t("notifications.filter.creator_coin") }} / $DESO</span>
    </div>

    <div class="mt-5px d-flex flex-row align-items-center">
      <button
        class="checkbox-circle mr-10px"
        [ngClass]="{ checked: !filteredOutSet.hasOwnProperty('follow') }"
        (click)="updateFilters('follow')"
      >
        <i-feather name="check"></i-feather>
      </button>
      <i-feather name="user" class="feather-medium fs-0px mr-5px"></i-feather>
      <span>{{ t("notifications.filter.follows") }}</span>
    </div>

    <div class="mt-5px d-flex flex-row align-items-center">
      <button
        class="checkbox-circle mr-10px"
        [ngClass]="{ checked: !filteredOutSet.hasOwnProperty('post') }"
        (click)="updateFilters('post')"
      >
        <i-feather name="check"></i-feather>
      </button>
      <i-feather name="message-square" class="feather-medium fs-0px mr-5px"></i-feather>
      <span>{{ t("notifications.filter.mentions_replies_reposts") }}</span>
    </div>

    <div class="mt-5px d-flex flex-row align-items-center">
      <button
        class="checkbox-circle mr-10px"
        [ngClass]="{ checked: !filteredOutSet.hasOwnProperty('nft') }"
        (click)="updateFilters('nft')"
      >
        <i-feather name="check"></i-feather>
      </button>
      <i-feather name="award" class="feather-medium fs-0px mr-5px"></i-feather>
      <span>{{ t("notifications.filter.nft") }}</span>
    </div>
  </div>
  <div class="w-100 border-color-grey border-bottom"></div>
  <div class="p-15px">
    <span class="messages-filter-menu__title" style="margin-left: 0px">{{ t("notifications.filter.view") }}</span>
    <div class="mt-10px d-flex flex-row align-items-center">
      <button
        class="checkbox-circle mr-10px"
        [ngClass]="{ checked: expandNotifications }"
        (click)="updateCompactView()"
      >
        <i-feather name="check"></i-feather>
      </button>
      <span>{{ t("notifications.filter.expanded") }}</span>
    </div>
  </div>
  <div class="d-flex flex-center w-100 pb-15px">
    <div class="w-100 messages-filter-menu__buttons">
      <button class="btn" (click)="updateSettings()">{{ t("notifications.filter.update") }}</button>
      <a class="cursor-pointer fc-muted" (click)="closeFilter.emit()">{{ t("notifications.filter.cancel") }}</a>
    </div>
  </div>
</div>
