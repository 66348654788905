import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DeSoGuardService {
  determineIconType(association, firstTransactionTimestamp: string) {
    const result = {
      iconType: '',
      iconColor: '',
      DeSoGuardScore: '',
    };
    const associationValue = association?.associationValue;
    const firstTransactionDate = new Date(firstTransactionTimestamp);
    const currentDate = new Date();
    const timeDifference = currentDate.getTime() - firstTransactionDate.getTime();
    const daysDifference = timeDifference / (1000 * 3600 * 24);
    const newAccount = daysDifference <= 30;

    if (newAccount && associationValue === 'Human') {
      result.iconType = 'fa-leaf';
      result.DeSoGuardScore = 'DeSoGuard Score: New Account';
      result.iconColor = 'text-success'; // Bootstrap class for green
    } else {
      switch (associationValue) {
        case 'Bot-like':
          result.iconType = 'fa-robot';
          result.DeSoGuardScore = 'DeSoGuard Score: Possible Bot-like behavior';
          result.iconColor = 'text-danger'; // Bootstrap class for red
          break;
        case 'Human':
          result.iconType = 'fa-user-check';
          result.DeSoGuardScore = 'DeSoGuard Score: Human';
          result.iconColor = 'text-primary'; // Bootstrap class for blue
          break;
        case 'Scam-like':
          result.iconType = 'fa-exclamation-triangle';
          result.DeSoGuardScore = 'DeSoGuard Score: Possible Scam-like behavior';
          result.iconColor = 'text-danger'; // Bootstrap class for red
          break;
        case 'AI-Content':
          result.iconType = 'fa-microchip';
          result.DeSoGuardScore = 'DeSoGuard Score: Possible AI content';
          result.iconColor = 'text-danger'; // Bootstrap class for red
          break;
        default:
          result.iconType = 'fa-question-circle';
          result.DeSoGuardScore = 'DeSoGuard Score: Profile Not Scanned';
          result.iconColor = 'text-danger'; // Bootstrap class for red
          break;
      }
    }

    return result;
  }
}
