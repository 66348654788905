import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { Subscription, zip } from "rxjs";
import { map } from "rxjs/operators";
import { TrackingService } from "src/app/tracking.service";
import { WelcomeModalComponent } from "src/app/welcome-modal/welcome-modal.component";
import { FollowChangeObservableResult } from "../../../lib/observable-results/follow-change-observable-result";
import { AppRoutingModule } from "../../app-routing.module";
import { BackendApiService } from "../../backend-api.service";
import { FollowButtonComponent } from "../../follow-button/follow-button.component";
import { GlobalVarsService } from "../../global-vars.service";
import { GraphqlService } from 'src/app/graphql.service';
import { TradeCreatorModalComponent } from "../../trade-creator-page/trade-creator-modal/trade-creator-modal.component";
import { UpdateProfileModalComponent } from "../../update-profile-page/update-profile-modal/update-profile-modal.component";
import { DeSoGuardService } from "src/app/deso-guard.service";
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';

@Component({
  selector: "creator-profile-top-card",
  templateUrl: "./creator-profile-top-card.component.html",
  styleUrls: ["./creator-profile-top-card.component.scss"],
})
export class CreatorProfileTopCardComponent implements OnInit, OnDestroy {
  @ViewChild(FollowButtonComponent, { static: false }) childFollowComponent;

  @Input() profile: any;

  // emits the UserUnblocked event
  @Output() userUnblocked = new EventEmitter();

  // emits the UserUnblocked event
  @Output() userBlocked = new EventEmitter();

  AppRoutingModule = AppRoutingModule;
  globalVars: GlobalVarsService;
  followChangeSubscription: Subscription;
  followerCount: number = null;
  followingCount: number = null;
  refreshFollowingBeingCalled = false;
  publicKeyIsCopied = false;
  queryResult: any;
  firstTransactionTimestamp: any;
  iconType: string;
  iconColor: string;
  loading: boolean;
  DeSoGuardScore: string;
  newAccount: boolean;

  constructor(
    private _globalVars: GlobalVarsService,
    private backendApi: BackendApiService,
    private router: Router,
    private graphqlService: GraphqlService,
    private modalService: BsModalService,
    private tracking: TrackingService,
    private deSoGuardService: DeSoGuardService,
    private http: HttpClient // Inject HttpClient here
  ) {
    this.globalVars = _globalVars;

    // If the user follows/unfollows this user, update the follower count
    this.followChangeSubscription = this.globalVars.followChangeObservable.subscribe((followChangeObservableResult) => {
      this._handleFollowChangeObservableResult(followChangeObservableResult);
    });
  }

  showElipsisDropdownMenu() {
    return (
      this.globalVars.loggedInUser &&
      (!this.profileBelongsToLoggedInUser() ||
        ((!this.profile.IsFeaturedTutorialUpAndComingCreator || !this.profile.IsFeaturedTutorialWellKnownCreator) &&
          this.globalVars.showSuperAdminTools()))
    );
  }

  profileBelongsToLoggedInUser(): boolean {
    return (
      this.globalVars.loggedInUser?.ProfileEntryResponse &&
      this.globalVars.loggedInUser.ProfileEntryResponse.PublicKeyBase58Check === this.profile.PublicKeyBase58Check
    );
  }

  ngOnDestroy() {
    this.followChangeSubscription.unsubscribe();
  }

  ngOnInit() {
    this._refreshFollowing();
    //this.fetchGraphqlData();
    this.fetchApiData();
  }

  fetchGraphqlData() {
    this.loading = true;
    const query = `query Query($publicKey: String!, $filter: UserAssociationFilter) {
      accountByPublicKey(publicKey: $publicKey) {
        userAssociationsAsTarget(filter: $filter) {
          nodes {
            extraData
            associationType
            associationValue
            associationId
            transactor {
              publicKey
            }
          }
        }
        transactionStats {
          firstTransactionTimestamp
        }
      }
    }`;
  
    const variables = {
      publicKey: this.profile?.PublicKeyBase58Check,
      filter: {
        transactor: {
          publicKey: {
            equalTo: 'BC1YLiTqKXFSC69zCrNkTAzmtskGyWZgDc5MjsT9p64V8mxvRGaVQky',
          },
        },
        associationType: {
          equalTo: 'DeSoGuard',
        },
      },
    };
  
    this.graphqlService.queryGraphql(query, variables).subscribe(
      (res) => {
        const accountData = res.data.accountByPublicKey;
        this.queryResult = accountData?.userAssociationsAsTarget?.nodes || [];
        
        // Check if transactionStats exists and is not null
        this.firstTransactionTimestamp = accountData?.transactionStats?.firstTransactionTimestamp || null;
  
        this.loading = false;
        this.determineIconType();
      },
      (err) => {
        console.error(err);
        this.loading = false;
      }
    );
  }

  fetchApiData() {
    this.loading = true;
    
    // Set up the request body for the user associations
    const associationPostData = {
        TransactorPublicKeyBase58Check: "BC1YLiTqKXFSC69zCrNkTAzmtskGyWZgDc5MjsT9p64V8mxvRGaVQky",
        AssociationType: "DeSoGuard",
        TargetUserPublicKeyBase58Check: this.profile?.PublicKeyBase58Check // dynamically pass the target public key
    };

    // Set up the request body for the transaction info
    const transactionPostData = {
        PublicKeyBase58Check: this.profile?.PublicKeyBase58Check,
        LastPublicKeyTransactionIndex: 1, // Requesting the first transaction
        Limit: 1
    };

    // Use forkJoin to make both requests at the same time
    forkJoin({
        association: this.http.post('https://node.deso.org/api/v0/user-associations/query', associationPostData),
        transaction: this.http.post('https://node.deso.org/api/v1/transaction-info', transactionPostData)
    }).subscribe(
      ({ association, transaction }: any) => {
        // Handle association result
        const assocData = association?.Associations?.[0];
        if (assocData) {
          this.queryResult = {
            associationId: assocData.AssociationID,
            associationType: assocData.AssociationType,
            associationValue: assocData.AssociationValue,
            extraData: assocData.ExtraData,
            transactor: {
              publicKey: assocData.TransactorPublicKeyBase58Check
            },
            targetUserPublicKey: assocData.TargetUserPublicKeyBase58Check,
            blockHeight: assocData.BlockHeight
          };
        } else {
          this.queryResult = null; // Handle case where no associations are found
        }

        // Handle transaction result
        const transactionData = transaction?.Transactions?.[0];
        if (transactionData) {
          const timestampSecs = transactionData.BlockInfo.TimestampSecs;
          const firstTransactionDate = new Date(timestampSecs * 1000); // Convert to milliseconds

          // Save the formatted timestamp
          this.firstTransactionTimestamp = firstTransactionDate.toISOString();
        } else {
          this.firstTransactionTimestamp = null; // Handle no transactions found
        }

        // Once both API responses are handled, finalize loading and determine icon type
        this.loading = false;
        this.determineIconType();
      },
      (err) => {
        console.error('Error fetching data:', err);
        this.loading = false;
      }
    );
}

  

  determineIconType() {
    if (this.queryResult) {
      const association = this.queryResult;
      const iconData = this.deSoGuardService.determineIconType(association, this.firstTransactionTimestamp);
      this.iconType = iconData.iconType;
      this.iconColor = iconData.iconColor;
      this.DeSoGuardScore = iconData.DeSoGuardScore;
    } else {
      // Default to question circle if no results
      this.iconType = 'fa-question-circle';
      this.iconColor = 'text-danger'; // Bootstrap class for red
      this.DeSoGuardScore = 'DeSoGuard Score: Profile Not Scanned';
    }
  }

  unblock() {
    this.userUnblocked.emit(this.profile.PublicKeyBase58Check);
  }

  block() {
    this.userBlocked.emit(this.profile.PublicKeyBase58Check);
  }

  reportUser(): void {
    this.tracking.log("profile : report", {
      username: this.profile.Username,
      publicKey: this.profile.PublicKeyBase58Check,
    });
    window.open(
      `https://desoreporting.aidaform.com/account?ReporterPublicKey=${this.globalVars.loggedInUser?.PublicKeyBase58Check}&ReportedAccountPublicKey=${this.profile.PublicKeyBase58Check}&ReportedAccountUsername=${this.profile.Username}`
    );
  }

  openModal(event) {
    // Prevent the post navigation click from occurring.
    event.stopPropagation();

    const initialState = { loggedInUser: this.globalVars.loggedInUser };
    // If the user has an account and a profile, open the modal so they can comment.
    this.modalService.show(UpdateProfileModalComponent, {
      class: "modal-dialog-centered update-profile-modal",
      initialState,
    });
  }

  messageUser(): void {
    this.router.navigate(["/" + this.globalVars.RouteNames.INBOX_PREFIX], {
      queryParams: { username: this.profile.Username },
    });
  }

  coinsInCirculation() {
    return this.profile.CoinEntry.CoinsInCirculationNanos / 1e9;
  }

  usdMarketCap() {
    return this.globalVars.abbreviateNumber(
      this.globalVars.nanosToUSDNumber(this.coinsInCirculation() * this.profile.CoinPriceDeSoNanos),
      3,
      true
    );
  }

  totalUSDLocked() {
    return this.globalVars.abbreviateNumber(
      this.globalVars.nanosToUSDNumber(this.profile.CoinEntry.DeSoLockedNanos),
      3,
      true
    );
  }

  _handleFollowChangeObservableResult(followChangeObservableResult: FollowChangeObservableResult) {
    if (followChangeObservableResult.followedPubKeyBase58Check === this.profile.PublicKeyBase58Check) {
      if (followChangeObservableResult.isFollowing) {
        this.followerCount += 1;
      } else {
        this.followerCount -= 1;
      }
    }
  }

  openBuyCreatorCoinModal(event) {
    event.stopPropagation();

    if (!this.globalVars.loggedInUser) {
      this.modalService.show(WelcomeModalComponent, { initialState: { triggerAction: "cc-buy" } });
      return;
    }

    const initialState = { username: this.profile.Username, tradeType: this.globalVars.RouteNames.BUY_CREATOR };
    this.modalService.show(TradeCreatorModalComponent, {
      class: "modal-dialog-centered buy-deso-modal",
      initialState,
    });
  }

  getCoverImageUrl() {
    if (this.profile?.ExtraData?.FeaturedImageURL) {
      return `url(${this.profile?.ExtraData?.FeaturedImageURL})`;
    } else {
      return "";
    }
  }

  _copyPublicKey() {
    this.globalVars._copyText(this.profile.PublicKeyBase58Check);
    this.publicKeyIsCopied = true;
    setInterval(() => {
      this.publicKeyIsCopied = false;
    }, 1000);
  }

  // Here we're making two calls to fetch one follower and one followed user (and their profiles),
  // but we only need the counts. Maybe we should allow the GetFollows response to return only
  // the counts, and maybe both at once.
  _refreshFollowing() {
    if (this.refreshFollowingBeingCalled) {
      return;
    }

    this.refreshFollowingBeingCalled = true;

    // TODO: need a loading treatment while loading OR need to obtain all follows on pageload

    const getFollowers = this.backendApi
      .GetFollows(
        this.profile.Username,
        "" /* PublicKeyBase58Check */,
        true /* get followers */,
        "" /* GetEntriesFollowingUsername */,
        0 /* NumToFetch */
      )
      .pipe(map((res) => res.NumFollowers));

    const getFollowing = this.backendApi
      .GetFollows(
        this.profile.Username,
        "" /* PublicKeyBase58Check */,
        false /* get following */,
        "" /* GetEntriesFollowingUsername */,
        0 /* NumToFetch */
      )
      .pipe(map((res) => res.NumFollowers));

    zip(getFollowers, getFollowing).subscribe(
      (res) => {
        this.followerCount = res[0];
        this.followingCount = res[1];
        this.refreshFollowingBeingCalled = false;
      },
      (error) => {
        this.refreshFollowingBeingCalled = false;
        // fail silently
        console.error(error);
      }
    );
  }

  _isLoggedInUserFollowing() {
    if (!this.globalVars.loggedInUser?.PublicKeysBase58CheckFollowedByUser) {
      return false;
    }

    return this.globalVars.loggedInUser.PublicKeysBase58CheckFollowedByUser.includes(this.profile.PublicKeyBase58Check);
  }

  // When a user blocks a profile, we make sure to unfollow that profile if the user is currently following it.
  _unfollowIfBlocked() {
    if (this._isLoggedInUserFollowing()) {
      this.childFollowComponent.unfollow();
    }
  }

  getFoundersRewardPercent() {
    return this.profile.CoinEntry.CreatorBasisPoints / 100;
  }

  getUserDetailsLink() {
    return `https://wallet.deso.com/?user=${this.profile?.Username}&tab=activity`;
  }
}
