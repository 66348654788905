<div
  class="position-relative creator-profile__container creator-profile__container-cover"
  [style.--cover-image]="getCoverImageUrl()"
  style="background-repeat: no-repeat; background-size: cover"
>
  <div class="d-flex flex-column flex-md-row js-creator-profile-top-card-container creator-profile__top-card-profile-row">
    <div
      [ngClass]="{
        nft__avatar: profile?.ExtraData?.NFTProfilePictureUrl
      }"
      class="creator-profile__avatar position-relative"
      [avatar]="profile.PublicKeyBase58Check"
      [nftProfileUrl]="profile?.ExtraData?.NFTProfilePictureUrl"
    ></div>
    <div
      [ngClass]="{
        nft__avatar: profile?.ExtraData?.NFTProfilePictureUrl
      }"
      class="creator-profile__avatar-border"
    ></div>
    <div class="d-flex flex-column creator-profile__username-price">
      <div class="fs-24px font-weight-bold d-flex align-items-center">
        &#64;{{ profile.Username }}

        <div
          *ngIf="profile.IsReserved && !profile.IsVerified"
          (click)="tooltip.toggle()"
          class="ml-2 cursor-pointer fs-18px lh-12px fc-muted"
          matTooltipClass="global__mat-tooltip global__mat-tooltip-font-size"
          [matTooltip]="'This profile is reserved'"
          #tooltip="matTooltip"
        >
          <i class="far fa-clock fa-sm align-middle"></i>
        </div>
        <a
          href="https://desoguard.com?publicKey={{profile?.PublicKeyBase58Check}}&userName={{profile?.Username}}"
          target="_blank"
          class="fs-14px fc-secondary"
        >
        <div *ngIf="iconType" class="ml-2 cursor-pointer fs-18px lh-12px" [matTooltip]="DeSoGuardScore" #tooltip="matTooltip">
              <i [class]="'fas ' + iconType" [ngClass]="iconColor"></i>
        </div>
        </a>
      </div>
      <div class="d-flex flex-row align-items-center">
        <a
          href="https://desoguard.com?publicKey={{profile?.PublicKeyBase58Check}}&userName={{profile?.Username}}"
          target="_blank"
          class="fs-14px fc-secondary"
        >
        <i-feather name="shield" class="feather-small mr-1" style="vertical-align: 1.5px;"></i-feather>
        <span class="fs-14px">Scan with DeSoGuard</span>
        </a>
      </div>
      
      <div *ngIf="profile.CoinPriceDeSoNanos > 0" style="whitespace: nowrap" class="d-flex">
        <div class="font-weight-bold" style="display: inline">
          ≈{{ globalVars.nanosToUSD(profile.CoinPriceDeSoNanos, 2) }}
          <span class="fc-muted font-weight-normal">{{ "creator_profile_top_card.coin_price" | transloco }}</span>
        </div>
      </div>
      <div *ngIf="profile?.ExtraData?.NFTProfilePicturePostHashHex">
        <a
          [routerLink]="['/' + globalVars.RouteNames.NFT, profile?.ExtraData?.NFTProfilePicturePostHashHex]"
          class="fs-14px fc-secondary"
        >
          <u>View NFT</u>
        </a>
      </div>
    </div>
    <div class="creator-profile__update-buy mt-2 mt-md-0">
      <div *ngIf="showElipsisDropdownMenu()" class="fs-15px pr-3 d-inline" dropdown container="body">
        <a class="link--unstyled text-grey9 link--unstyled text-grey9" dropdownToggle>
          <i class="fas fa-ellipsis-h"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right bc-dropdown-menu p-0 border fs-15px" *dropdownMenu>
          <div>
            <a
              class="dropdown-menu-item d-block link--unstyled p-2 feed-post__dropdown-menu-item"
              (click)="messageUser()"
              *ngIf="!profileBelongsToLoggedInUser()"
            >
              {{ "creator_profile_top_card.message_user" | transloco }}
            </a>
            <a
              class="dropdown-menu-item d-block link--unstyled p-2 feed-post__dropdown-menu-item"
              (click)="_copyPublicKey()"
            >
              Copy User's Public Key
            </a>
            <a
              class="dropdown-menu-item d-block link--unstyled p-2 feed-post__dropdown-menu-item"
              (click)="globalVars.hasUserBlockedCreator(profile.PublicKeyBase58Check) ? unblock() : block()"
              *ngIf="!profileBelongsToLoggedInUser()"
            >
              {{
                globalVars.hasUserBlockedCreator(profile.PublicKeyBase58Check)
                  ? "creator_profile_top_card.unblock"
                  : ("creator_profile_top_card.block" | transloco)
              }}
              {{ "creator_profile_top_card.user" | transloco }}
            </a>
            <a
              class="dropdown-menu-item d-block link--unstyled p-2 feed-post__dropdown-menu-item"
              (click)="reportUser()"
              *ngIf="!profileBelongsToLoggedInUser()"
            >
              {{ "creator_profile_top_card.report_user" | transloco }}
            </a>
          </div>
        </div>
      </div>

      <div *ngIf="globalVars.loggedInUser" class="d-inline-block fs-15px creator-profile-top-card__edit-btn">
        <div *ngIf="profileBelongsToLoggedInUser(); else elseBlock">
          <!-- Show update profile link -->
          <a (click)="openModal($event)">
            <i-feather name="settings"></i-feather>
            {{ "creator_profile_top_card.edit" | transloco }}
          </a>
        </div>
        <ng-template #elseBlock>
          <!-- Show follow button-->
          <follow-button
            *ngIf="!globalVars.hasUserBlockedCreator(profile.PublicKeyBase58Check)"
            [displayAsLink]="true"
            [followedPubKeyBase58Check]="profile.PublicKeyBase58Check"
          ></follow-button>
        </ng-template>
      </div>
      <a
        class="btn creator-profile-top-card__buy-btn"
        (click)="openBuyCreatorCoinModal($event)"
        *ngIf="
          !globalVars.isMobile() &&
          (getFoundersRewardPercent() < 100 ||
            profile.PublicKeyBase58Check === globalVars?.loggedInUser?.PublicKeyBase58Check)
        "
      >
        {{ "creator_profile_top_card.buy" | transloco }}
      </a>
      <a
        class="btn btn-danger font-weight-bold ml-3 fs-14px creator-profile-top-card__block-btn"
        *ngIf="globalVars.hasUserBlockedCreator(profile.PublicKeyBase58Check)"
        (click)="unblock()"
      >
        <span>{{ "creator_profile_top_card.unblock" | transloco }}</span>
      </a>
    </div>
  </div>
  <div
    class="d-flex align-items-center justify-content-end pt-3 mr-3 js-creator-profile-top-card-container"
  ></div>

  <div class="d-flex flex-column">
    <div class="fs-15px py-3 text-break" [innerHTML]="profile.Description | sanitizeAndAutoLink"></div>
    <div class="creator-profile__divider-border"></div>
    <div class="fs-15px d-flex flex-column flex-wrap">
      <div
        class="d-flex justify-content-between"
        [ngClass]="{ 'flex-column align-items-center': globalVars.isMobile() }"
      >
        <div class="d-flex justify-content-center" [ngClass]="{ 'justify-content-between': globalVars.isMobile() }">
          <a
            class="link--unstyled"
            *ngIf="followerCount !== undefined && followerCount !== null"
            [routerLink]="AppRoutingModule.userFollowersPath(profile.Username)"
            queryParamsHandling="merge"
          >
            <span class="font-weight-bold">{{ followerCount }}&nbsp;</span>
            <span class="fc-muted">{{ "creator_profile_top_card.followers" | transloco }}&nbsp;&nbsp;&nbsp;</span>
          </a>

          <a
            class="link--unstyled"
            *ngIf="followingCount !== undefined && followingCount !== null"
            [routerLink]="AppRoutingModule.userFollowingPath(profile.Username)"
            queryParamsHandling="merge"
          >
            <span class="font-weight-bold">{{ followingCount }}&nbsp;</span>
            <span class="fc-muted">{{ "creator_profile_top_card.following" | transloco }}&nbsp;&nbsp;&nbsp;</span>
          </a>
          <div>
            <span class="font-weight-bold">&nbsp;{{ getFoundersRewardPercent() | number : "0.0-9" }}%</span>
            <span class="fc-muted">&nbsp;FR</span>
          </div>
        </div>
        <div *ngIf="profile?.Username" [ngClass]="{ 'mt-3': globalVars.isMobile() }">
          <a class="fc-blue" [href]="getUserDetailsLink()" target="_blank">View Activity</a>
        </div>
      </div>
      <button
        class="primary-button w-100 mt-4"
        (click)="openBuyCreatorCoinModal($event)"
        *ngIf="globalVars.isMobile()"
      >
        Buy
      </button>
    </div>
  </div>
</div>
