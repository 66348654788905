<div
  class="d-flex align-items-center messages-thread__container border-bottom border-color-grey px-15px pb-15px pt-10px cursor-pointer"
  [ngClass]="{ 'messages-thread__container--selected': isSelected }"
  role="button"
  tabindex="0"
  (click)="onItemClick(listItem)"
>
  <div>
    <ng-container *ngIf="isGroupChat; else dmChatAvatar">
      <img
        [src]="'https://icotar.com/initials/' + initials + '?s=' + chatImageSize"
        [width]="chatImageSize"
        class="rounded-circle"
        [alt]="chatName"
      />
    </ng-container>

    <ng-template #dmChatAvatar>
      <div class="messages-thread__avatar" [avatar]="previewPublicKey"></div>
    </ng-template>
  </div>
  <div class="ml-3 messages-thread__ellipsis-restriction fs-15px">
    <span class="font-weight-bold" [title]="chatName">
      {{ chatName }}
    </span>
    <ng-container *ngIf="!isGroupChat">
      <!--
      <span *ngIf="profile?.IsVerified" class="ml-1 text-primary">
        <i class="fas fa-check-circle fa-sm align-middle"></i>
      </span>
      -->
      <span
        *ngIf="profile"
        class="messages-thread__border-radius ml-5px font-weight-normal px-10px py-2px mt-5px background-color-light-grey"
        style="width: fit-content"
      >
        {{ globalVars.nanosToUSD(globalVars._parseFloat(profile.CoinPriceDeSoNanos), 2) }}
      </span>
    </ng-container>
    <div class="messages-thread__ellipsis-restriction">
      {{ listItem.DecryptedMessage }}
    </div>
  </div>
  <div class="messages-thread__timestamp">
    {{ globalVars.convertTstampToDateOrTime(listItem.MessageInfo.TimestampNanos) }}
  </div>
</div>
