<div class="trend-entry" *transloco="let t">
  <!--  <div *ngIf="activeTab !== RightBarCreatorsComponent.HASHTAGS.name">-->
  <div>
    <a
      *ngFor="
        let profileEntryResponse of activeTab === RightBarCreatorsComponent.GAINERS.name
          ? globalVars.topGainerLeaderboard
          : activeTab === RightBarCreatorsComponent.DIAMONDS.name
          ? globalVars.topDiamondedLeaderboard
          : activeTab === RightBarCreatorsComponent.ALL_TIME.name
          ? globalVars.topCreatorsAllTimeLeaderboard
          : []
      "
      (click)="navigateToUser(profileEntryResponse.Profile.Username)"
      queryParamsHandling="merge"
      class="link--unstyled d-flex align-items-center"
    >
      <div class="trend-holder d-flex align-items-center">
        <div
          class="right-bar-creators-leaderboard__creator-avatar"
          [avatar]="profileEntryResponse.Profile.PublicKeyBase58Check"
        ></div>
        <div>
          <div class="d-flex">
            <div class="trend-name" style="flex-grow: 1">
              <span>{{ profileEntryResponse.Profile.Username }}</span>
              <span
                *ngIf="profileEntryResponse.Profile.IsReserved && !profileEntryResponse.Profile.IsVerified"
                class="d-inline-block ml-1 cursor-pointer lh-12px"
                matTooltipClass="global__mat-tooltip global__mat-tooltip-font-size"
                [matTooltip]="t('right_bar.creators.leaderboard.reserved_profile')"
              >
                <i class="far fa-clock fa-sm"></i>
              </span>
              <!--
              <span
                *ngIf="profileEntryResponse.Profile.IsVerified"
                (click)="tooltip.toggle()"
                class="ml-1 cursor-pointer text-primary"
                matTooltipClass="global__mat-tooltip global__mat-tooltip-font-size"
                [matTooltip]="t('right_bar.creators.leaderboard.verified_account')"
                #tooltip="matTooltip"
              >
                <i-feather name="check-circle"></i-feather>
              </span>
              -->
            </div>
          </div>
          <div class="d-flex">
            <!-- Follow button-->
            <follow-button
              [displayAsLink]="true"
              [followLinkClass]="'link--unstyled'"
              [followedPubKeyBase58Check]="profileEntryResponse.Profile.PublicKeyBase58Check"
            ></follow-button>
          </div>
        </div>

        <div class="d-flex flex-column trend-end" style="align-items: flex-end">
          <div class="fc-green" *ngIf="activeTab === RightBarCreatorsComponent.GAINERS.name">
            +${{ globalVars.abbreviateNumber(globalVars.nanosToUSDNumber(profileEntryResponse.DeSoLockedGained), 2) }}
          </div>
          <div class="fc-blue" *ngIf="activeTab === RightBarCreatorsComponent.DIAMONDS.name">
            {{ globalVars.abbreviateNumber(profileEntryResponse.DiamondsReceived, 0) }}
            <i class="icon-diamond" style="margin-left: -5px"></i>
          </div>
          <div
            [ngClass]="{
              'text-grey7': activeTab !== RightBarCreatorsComponent.ALL_TIME.name,
              'text-grey5': activeTab === RightBarCreatorsComponent.ALL_TIME.name
            }"
            [ngStyle]="{
              'font-size': activeTab === RightBarCreatorsComponent.ALL_TIME.name ? '15px' : '10px'
            }"
          >
            ~{{ globalVars.nanosToUSD(profileEntryResponse.Profile.CoinPriceDeSoNanos, 2) }}
          </div>
        </div>
      </div>
    </a>
  </div>
  <!--  <div *ngIf="activeTab === RightBarCreatorsComponent.HASHTAGS.name">-->
  <!--    <a-->
  <!--      *ngFor="let hashTag of globalVars.hashtagLeaderboard"-->
  <!--      (click)="navigateToHashtag(hashTag.Hashtag)"-->
  <!--      queryParamsHandling="merge"-->
  <!--      class="link&#45;&#45;unstyled d-flex align-items-center"-->
  <!--    >-->
  <!--      <div class="trend-holder d-flex align-items-center">-->
  <!--        <div>-->
  <!--          <div class="d-flex">-->
  <!--            <div class="trend-name" style="flex-grow: 1">-->
  <!--              <span>{{ hashTag.Hashtag }}</span>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->

  <!--        <div class="d-flex flex-column trend-end" style="align-items: flex-end">-->
  <!--          <div class="text-grey5 fs-15px">-->
  <!--            {{ hashTag.Count }}-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </a>-->
  <!--  </div>-->
</div>
