import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { isNil } from "lodash";
import { BsModalService } from "ngx-bootstrap/modal";
import { IAdapter, IDatasource } from "ngx-ui-scroll";
import { InfiniteScroller } from "src/app/infinite-scroller";
import { WelcomeModalComponent } from "src/app/welcome-modal/welcome-modal.component";
import { environment } from "src/environments/environment";
import { CanPublicKeyFollowTargetPublicKeyHelper } from "../../../lib/helpers/follows/can_public_key_follow_target_public_key_helper";
import { AppRoutingModule } from "../../app-routing.module";
import { BackendApiService } from "../../backend-api.service";
import { GlobalVarsService } from "../../global-vars.service";
import { TradeCreatorModalComponent } from "../../trade-creator-page/trade-creator-modal/trade-creator-modal.component";

@Component({
  selector: "creators-leaderboard",
  templateUrl: "./creators-leaderboard.component.html",
  styleUrls: ["./creators-leaderboard.component.scss"],
})
export class CreatorsLeaderboardComponent implements OnInit {
  static PAGE_SIZE = 50;
  static WINDOW_VIEWPORT = false;
  static BUFFER_SIZE = 5;
  @Output() closeModal = new EventEmitter();
  @Input() isModal = false;

  AppRoutingModule = AppRoutingModule;
  appData: GlobalVarsService;
  profileEntryResponses = [];
  isLeftBarMobileOpen = false;
  isLoadingProfilesForFirstTime = false;
  isLoadingMore: boolean = false;
  profilesToShow = [];

  // FIME: Replace with real value
  fakeNumHodlers = Math.ceil(Math.random() * 1000) + 1000;

  // stores a mapping of page number to public key to fetch
  pagedKeys = {
    0: "",
  };

  // tracks if we've reached the end of all notifications
  lastPage = null;

  // Cached profiles after filtering and randomization
  cachedProfiles: any[] = null;

  constructor(
    public globalVars: GlobalVarsService,
    private backendApi: BackendApiService,
    private titleService: Title,
    private modalService: BsModalService
  ) {
    this.appData = globalVars;
  }

  getPage(page: number): Promise<any[]> {
    if (!isNil(this.lastPage) && page > this.lastPage) {
      return Promise.resolve([]);
    }
  
    this.isLoadingMore = true;
  
    return new Promise((resolve, reject) => {
      const checkData = () => {
        if (
          this.globalVars.deSoGuardAssociations &&
          Object.keys(this.globalVars.deSoGuardAssociations).length > 0
        ) {
          // Check if we have already cached the profiles
          if (!this.cachedProfiles) {
            // Process data
            const associations = this.globalVars.deSoGuardAssociations;
  
            // Convert associations to array of profiles, applying filters
            const profiles = [];
            const addedPublicKeys = new Set<string>(); // Keep track of added profiles
  
            for (const publicKey in associations) {
              if (associations.hasOwnProperty(publicKey)) {
                const associationList = associations[publicKey];
                for (const association of associationList) {
                  if (association.associationValue === "Human") {
                    const target = association.target;
                    if (target) {
                      const username = target.username || "";
                      const coinPriceDeSoNanos = parseFloat(target.coinPriceDesoNanos) || 0;
  
                      // Get firstTransactionTimestamp
                      const firstTransactionTimestampStr = target.transactionStats?.firstTransactionTimestamp;
                      if (!firstTransactionTimestampStr || firstTransactionTimestampStr === "") {
                        continue; // Skip if firstTransactionTimestamp is null or empty
                      }
                      const firstTransactionTimestamp = new Date(firstTransactionTimestampStr);
                      if (isNaN(firstTransactionTimestamp.getTime())) {
                        continue; // Skip if invalid date
                      }
                      const thirtyDaysAgo = new Date();
                      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
                      if (firstTransactionTimestamp >= thirtyDaysAgo) {
                        continue; // Skip if the first transaction was less than 30 days ago
                      }
  
                      // Get latestTransactionTimestamp
                      const latestTransactionTimestampStr =
                        target.transactionStats?.latestTransactionTimestamp;
                      if (!latestTransactionTimestampStr) {
                        continue; // Skip if no latestTransactionTimestamp
                      }
                      const latestTransactionTimestamp = new Date(latestTransactionTimestampStr);
                      if (isNaN(latestTransactionTimestamp.getTime())) {
                        continue; // Skip if invalid date
                      }
  
                      // Apply additional filters
                      if (
                        username !== "" &&
                        coinPriceDeSoNanos > 1000000.0 &&
                        latestTransactionTimestamp >= thirtyDaysAgo
                      ) {
                        const profilePublicKey = target.publicKey || publicKey;
                        if (addedPublicKeys.has(profilePublicKey)) {
                          continue; // Skip if profile already added
                        }
  
                        // Map target to profile format
                        const profile = {
                          PublicKeyBase58Check: profilePublicKey,
                          Username: username,
                          Description: target.description || null,
                          IsHidden: false,
                          IsReserved: false,
                          IsVerified: false,
                          Comments: null,
                          Posts: null,
                          CoinEntry: {
                            CreatorBasisPoints: 0,
                            DeSoLockedNanos: coinPriceDeSoNanos,
                            NumberOfHolders: 0,
                            CoinsInCirculationNanos: 0,
                            CoinWatermarkNanos: 0,
                            BitCloutLockedNanos: coinPriceDeSoNanos,
                          },
                          DAOCoinEntry: {
                            NumberOfHolders: 0,
                            CoinsInCirculationNanos: "0x0",
                            MintingDisabled: false,
                            TransferRestrictionStatus: "unrestricted",
                            LockupTransferRestrictionStatus: "unrestricted",
                          },
                          CoinPriceDeSoNanos: coinPriceDeSoNanos,
                          CoinPriceBitCloutNanos: coinPriceDeSoNanos,
                          UsersThatHODL: null,
                          IsFeaturedTutorialWellKnownCreator: false,
                          IsFeaturedTutorialUpAndComingCreator: false,
                          ExtraData: target.extraData || null,
                          DESOBalanceNanos: 0,
                          BestExchangeRateDESOPerDAOCoin: 0,
                        };
                        profiles.push(profile);
                        addedPublicKeys.add(profilePublicKey); // Mark profile as added
                      }
                    }
                  }
                }
              }
            }
  
            // Randomize profiles using Fisher-Yates shuffle
            for (let i = profiles.length - 1; i > 0; i--) {
              const j = Math.floor(Math.random() * (i + 1));
              [profiles[i], profiles[j]] = [profiles[j], profiles[i]];
            }
  
            // Cache the randomized profiles
            this.cachedProfiles = profiles;
          }
  
          // Implement pagination on cachedProfiles
          const pageSize = CreatorsLeaderboardComponent.PAGE_SIZE; // Ensure this is defined
          const startIndex = page * pageSize;
          const endIndex = startIndex + pageSize;
          const pageProfiles = this.cachedProfiles.slice(startIndex, endIndex);
  
          // Update pagination tracking
          if (!this.pagedKeys) {
            // Initialize pagedKeys with required property '0'
            this.pagedKeys = { 0: null };
          } else if (!this.pagedKeys.hasOwnProperty('0')) {
            // Ensure property '0' exists
            this.pagedKeys[0] = null;
          }
          this.pagedKeys[page + 1] = null; // No next key since data is in memory
  
          if (pageProfiles.length < pageSize) {
            this.lastPage = page;
          }
  
          resolve(pageProfiles);
          this.isLoadingMore = false;
          this.isLoadingProfilesForFirstTime = false;
        } else {
          // Wait and check again
          setTimeout(checkData, 100);
        }
      };
      checkData();
    });
  }
  

  openBuyCreatorCoinModal(event, username: string) {
    event.stopPropagation();
    this.closeModal.emit();

    if (!this.globalVars.loggedInUser) {
      this.modalService.show(WelcomeModalComponent, { initialState: { triggerAction: "cc-buy" } });
      return;
    }

    const initialState = { username: username, tradeType: this.globalVars.RouteNames.BUY_CREATOR };
    this.modalService.show(TradeCreatorModalComponent, {
      class: "modal-dialog-centered buy-deso-modal",
      initialState,
    });
  }

  refreshData() {
    this.isLoadingProfilesForFirstTime = true;
    this.cachedProfiles = null; // Reset cached profiles
    this.infiniteScroller.reset();
    this.datasource.adapter.reset();
  }

  ngOnInit() {
    this.isLoadingProfilesForFirstTime = true;
    this.titleService.setTitle(`Buy Creator Coins - ${environment.appName.name}`);
    console.log(this.globalVars.deSoGuardAssociations);
  }

  canLoggedInUserFollowTargetPublicKey(targetPubKeyBase58Check) {
    return CanPublicKeyFollowTargetPublicKeyHelper.execute(
      this.appData.loggedInUser.PublicKeyBase58Check,
      targetPubKeyBase58Check
    );
  }

  infiniteScroller: InfiniteScroller = new InfiniteScroller(
    CreatorsLeaderboardComponent.PAGE_SIZE,
    this.getPage.bind(this),
    CreatorsLeaderboardComponent.WINDOW_VIEWPORT,
    CreatorsLeaderboardComponent.BUFFER_SIZE,
    0.5
  );
  datasource: IDatasource<IAdapter<any>> = this.infiniteScroller.getDatasource();
}
