//@ts-strict
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class TrackingService {

  constructor() {
    // All tracking services (Amplitude, Hotjar, Heap) have been removed.
  }

  /**
   * @param event should be in the format of <noun (object/category)> : <present-tense-verb>
   * e.g. "post : like", "signup-button : click", "onboarding-modal : open"
   * @param properties by default we log the current url path. You can pass any
   * additional properties you may want to log here. if status is not explicitly
   * set, we default to "error" if properties.error is set, and default to
   * success if not.
   */
  log(event: string, properties: Record<string, any> = {}) {
    // Tracking logic removed; this method is now effectively a no-op.
  }

  identifyUser(publicKey?: string, properties: Record<string, any> = {}) {
    // User identification logic removed; this method is now a no-op.
  }
}
